/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumbers {}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker { color: black; }
.CodeMirror-guttermarker-subtle { color: #999; }

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@-webkit-keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}
@keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {}

.cm-tab { display: inline-block; text-decoration: inherit; }

.CodeMirror-rulers {
  position: absolute;
  left: 0; right: 0; top: -50px; bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0; bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {color: blue;}
.cm-s-default .cm-quote {color: #090;}
.cm-negative {color: #d44;}
.cm-positive {color: #292;}
.cm-header, .cm-strong {font-weight: bold;}
.cm-em {font-style: italic;}
.cm-link {text-decoration: underline;}
.cm-strikethrough {text-decoration: line-through;}

.cm-s-default .cm-keyword {color: #708;}
.cm-s-default .cm-atom {color: #219;}
.cm-s-default .cm-number {color: #164;}
.cm-s-default .cm-def {color: #00f;}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {}
.cm-s-default .cm-variable-2 {color: #05a;}
.cm-s-default .cm-variable-3, .cm-s-default .cm-type {color: #085;}
.cm-s-default .cm-comment {color: #a50;}
.cm-s-default .cm-string {color: #a11;}
.cm-s-default .cm-string-2 {color: #f50;}
.cm-s-default .cm-meta {color: #555;}
.cm-s-default .cm-qualifier {color: #555;}
.cm-s-default .cm-builtin {color: #30a;}
.cm-s-default .cm-bracket {color: #997;}
.cm-s-default .cm-tag {color: #170;}
.cm-s-default .cm-attribute {color: #00c;}
.cm-s-default .cm-hr {color: #999;}
.cm-s-default .cm-link {color: #00c;}

.cm-s-default .cm-error {color: #f00;}
.cm-invalidchar {color: #f00;}

.CodeMirror-composing { border-bottom: 2px solid; }

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {color: #0b0;}
div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #a22;}
.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
.CodeMirror-activeline-background {background: #e8f2ff;}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px; margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0; top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0; left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0; bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0; bottom: 0;
}

.CodeMirror-gutters {
  position: absolute; left: 0; top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0; bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection { background-color: transparent }
.CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */ border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  -webkit-font-feature-settings: "calt";
          font-feature-settings: "calt";
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {}

.CodeMirror-rtl pre { direction: rtl; }

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre { position: static; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected { background: #d9d9d9; }
.CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
.CodeMirror-crosshair { cursor: crosshair; }
.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */
.cm-force-border { padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after { content: ''; }

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext { background: none; }

/**
    Name: IntelliJ IDEA darcula theme
    From IntelliJ IDEA by JetBrains
 */

.cm-s-darcula  { font-family: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif;}
.cm-s-darcula.CodeMirror { background: #2B2B2B; color: #A9B7C6; }

.cm-s-darcula span.cm-meta { color: #BBB529; }
.cm-s-darcula span.cm-number { color: #6897BB; }
.cm-s-darcula span.cm-keyword { color: #CC7832; line-height: 1em; font-weight: bold; }
.cm-s-darcula span.cm-def { color: #A9B7C6; font-style: italic; }
.cm-s-darcula span.cm-variable { color: #A9B7C6; }
.cm-s-darcula span.cm-variable-2 { color: #A9B7C6; }
.cm-s-darcula span.cm-variable-3 { color: #9876AA; }
.cm-s-darcula span.cm-type { color: #AABBCC; font-weight: bold; }
.cm-s-darcula span.cm-property { color: #FFC66D; }
.cm-s-darcula span.cm-operator { color: #A9B7C6; }
.cm-s-darcula span.cm-string { color: #6A8759; }
.cm-s-darcula span.cm-string-2 { color: #6A8759; }
.cm-s-darcula span.cm-comment { color: #61A151; font-style: italic; }
.cm-s-darcula span.cm-link { color: #CC7832; }
.cm-s-darcula span.cm-atom { color: #CC7832; }
.cm-s-darcula span.cm-error { color: #BC3F3C; }
.cm-s-darcula span.cm-tag { color: #629755; font-weight: bold; font-style: italic; text-decoration: underline; }
.cm-s-darcula span.cm-attribute { color: #6897bb; }
.cm-s-darcula span.cm-qualifier { color: #6A8759; }
.cm-s-darcula span.cm-bracket { color: #A9B7C6; }
.cm-s-darcula span.cm-builtin { color: #FF9E59; }
.cm-s-darcula span.cm-special { color: #FF9E59; }
.cm-s-darcula span.cm-matchhighlight { color: #FFFFFF; background-color: rgba(50, 89, 48, .7); font-weight: normal;}
.cm-s-darcula span.cm-searching { color: #FFFFFF; background-color: rgba(61, 115, 59, .7); font-weight: normal;}

.cm-s-darcula .CodeMirror-cursor { border-left: 1px solid #A9B7C6; }
.cm-s-darcula .CodeMirror-activeline-background { background: #323232; }
.cm-s-darcula .CodeMirror-gutters { background: #313335; border-right: 1px solid #313335; }
.cm-s-darcula .CodeMirror-guttermarker { color: #FFEE80; }
.cm-s-darcula .CodeMirror-guttermarker-subtle { color: #D0D0D0; }
.cm-s-darcula .CodeMirrir-linenumber { color: #606366; }
.cm-s-darcula .CodeMirror-matchingbracket { background-color: #3B514D; color: #FFEF28 !important; font-weight: bold; }

.cm-s-darcula div.CodeMirror-selected { background: #214283; }

.CodeMirror-hints.darcula {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #9C9E9E;
  background-color: #3B3E3F !important;
}

.CodeMirror-hints.darcula .CodeMirror-hint-active {
  background-color: #494D4E !important;
  color: #9C9E9E !important;
}

.pvtUi {
    color: #2a3f5f;
    font-family: Verdana;
    border-collapse: collapse;
}
.pvtUi select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtUi td.pvtOutput {
    vertical-align: top;
}

table.pvtTable {
    font-size: 8pt;
    text-align: left;
    border-collapse: collapse;
    margin-top: 3px;
    margin-left: 3px;
    font-family: Verdana;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    background-color: #ebf0f8;
    border: 1px solid #c8d4e3;
    font-size: 8pt;
    padding: 5px;
}

table.pvtTable .pvtColLabel {
    text-align: center;
}
table.pvtTable .pvtTotalLabel {
    text-align: right;
}

table.pvtTable tbody tr td {
    color: #2a3f5f;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #c8d4e3;
    vertical-align: top;
    text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
    font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
    cursor: pointer;
    width: 15px;
    margin-left: 5px;
    display: inline-block;
    user-select: none;
    text-decoration: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #a2b1c6;
    background: #f2f5fa;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}

.pvtRenderers {
    padding-left: 5px;
    -webkit-user-select: none;
            user-select: none;
}

.pvtDropdown {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    margin: 3px;
}

.pvtDropdownIcon {
    float: right;
    color: #a2b1c6;
}
.pvtDropdownCurrent {
    text-align: left;
    border: 1px solid #a2b1c6;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 210px;
    box-sizing: border-box;
    background: white;
}

.pvtDropdownCurrentOpen {
    border-radius: 4px 4px 0 0;
}

.pvtDropdownMenu {
    background: white;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #a2b1c6;
    border-top: 1px solid #dfe8f3;
    box-sizing: border-box;
}

.pvtDropdownValue {
    padding: 2px 5px;
    font-size: 12px;
    text-align: left;
}
.pvtDropdownActiveValue {
    background: #ebf0f8;
}

.pvtVals {
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    padding-bottom: 12px;
}

.pvtRows {
    height: 35px;
}

.pvtAxisContainer li {
    padding: 8px 6px;
    list-style-type: none;
    cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
    -webkit-border-radius: 5px;
    padding: 3px 15px;
    border-radius: 5px;
    border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
    display: none;
}

.pvtAxisContainer li span.pvtAttr {
    -webkit-text-size-adjust: 100%;
    background: #f3f6fa;
    border: 1px solid #c8d4e3;
    padding: 2px 5px;
    white-space: nowrap;
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtTriangle {
    cursor: pointer;
    color: #506784;
}

.pvtHorizList li {
    display: inline-block;
}
.pvtVertList {
    vertical-align: top;
}

.pvtFilteredAttribute {
    font-style: italic;
}

.sortable-chosen .pvtFilterBox {
    display: none !important;
}

.pvtCloseX {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none !important;
}

.pvtDragHandle {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 18px;
    cursor: move;
    color: #a2b1c6;
}

.pvtButton {
    color: #506784;
    border-radius: 5px;
    padding: 3px 6px;
    background: #f2f5fa;
    border: 1px solid;
    border-color: #c8d4e3;
    font-size: 14px;
    margin: 3px;
    transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
    text-decoration: none !important;
}

.pvtButton:hover {
    background: #e2e8f0;
    border-color: #a2b1c6;
}

.pvtButton:active {
    background: #d1dae6;
}

.pvtFilterBox input {
    border: 1px solid #c8d4e3;
    border-radius: 5px;
    color: #506784;
    padding: 0 3px;
    font-size: 14px;
}

.pvtFilterBox input:focus {
    border-color: #119dff;
    outline: none;
}

.pvtFilterBox {
    z-index: 100;
    width: 300px;
    border: 1px solid #506784;
    background-color: #fff;
    position: absolute;
    text-align: center;
    user-select: none;
    min-height: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtFilterBox h4 {
    margin: 15px;
}
.pvtFilterBox p {
    margin: 10px auto;
}
.pvtFilterBox button {
    color: #2a3f5f;
}
.pvtFilterBox input[type='text'] {
    width: 230px;
    color: #2a3f5f;
    margin-bottom: 5px;
}

.pvtCheckContainer {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: scroll;
    width: 100%;
    max-height: 30vh;
    border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
    margin: 0;
    margin-bottom: 1px;
    padding: 3px;
    cursor: default;
}

.pvtCheckContainer p.selected {
    background: #ebf0f8;
}

.pvtOnly {
    display: none;
    width: 35px;
    float: left;
    font-size: 12px;
    padding-left: 5px;
    cursor: pointer;
}

.pvtOnlySpacer {
    display: block;
    width: 35px;
    float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
    display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
    display: none;
}

.pvtRendererArea {
    padding: 5px;
}

